<template>
    <div class="container">
        <b-card>
            <b-row>
                <b-col cols="4">
                    <label for="referral_owner_rewarded_coins">Referal owner rewarded coins</label>
                    <b-form-input id="referral_owner_rewarded_coins" v-model="form.referral_owner_rewarded_coins" size="sm" />
                </b-col>
                <b-col cols="4">
                    <label for="referral_user_rewarded_coins">Referal user rewarded coins</label>
                    <b-form-input id="referral_user_rewarded_coins" v-model="form.referral_user_rewarded_coins" size="sm" />
                </b-col>
                <b-col cols="4">
                    <b-button @click="submitHandler" v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="danger" class="mt-1"> Submit </b-button>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import { BPopover, BBadge, BListGroup, BListGroupItem, BTable, BAvatar, BFormCheckbox, BFormSelect, BTab, BTabs, BCard, BAlert, BFormGroup, BForm, BRow, BCol, BFormInput, BMedia, BFormFile, BImg, BFormTextarea, BCardText, BButton, BMediaBody, BMediaAside, BFormInvalidFeedback, BOverlay, BPagination } from "bootstrap-vue";
import { ref, onBeforeUnmount, onMounted, watchEffect } from "@vue/composition-api";
import router from "@/router";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import UserRepository from "@/abstraction/repository/userRepository";
import useWalletList from "./useWalletList";
const userRepository = new UserRepository();

export default {
    components: {
        BPopover,
        BBadge,
        BListGroup,
        BListGroupItem,
        BPagination,
        BTable,
        BAvatar,
        BOverlay,
        BTab,
        BFormInput,
        BFormCheckbox,
        BFormInvalidFeedback,
        BFormGroup,
        BForm,
        BFormTextarea,
        BFormFile,
        BImg,
        BMedia,
        BTabs,
        BCard,
        BAlert,
        BRow,
        BCol,
        vSelect,
        BFormSelect,
        BButton,
        BCardText,
        BMediaAside,
        BMediaBody,
        // Form Validation
    },
    directives: {
        Ripple,
    },
    setup(props, context) {
        const { getReferalCode,setReferalCode } = useWalletList();
        const form = ref({});
        getReferalCode().then((res) => {
            form.value = res.data.data;
        });
        const submitHandler = () => {
            let data = {
                referral_owner_rewarded_coins: form.value.referral_owner_rewarded_coins,
                referral_user_rewarded_coins: form.value.referral_user_rewarded_coins,
                causer_id: JSON.parse(localStorage.getItem("userData")).id,
            };
            setReferalCode(data)
        };
        return { form, submitHandler };
    },
};
</script>

<style></style>
